<template>
  <div class="grid grid-cols-3 gap-6">
    <div class="col-span-3 lg:col-span-2 flex items-end">
      <p>
        <n-html :text="$t('report.common.date.subtitle')" />
      </p>
    </div>
    <div class="col-span-3 lg:col-span-1">
      <p class="mb-2 font-semibold">
        <n-html :text="$t('form.report_date.label')" />
      </p>
      <n-form-group id="input-group-date" class="margin-bottom-none">
        <n-form-input-default
          ref="datepicker"
          id="input-date"
          field_type="date"
          :auto-apply="true"
          :minDate="minDate"
          :maxDate="maxDate"
          :placeholder="$t('form.report_date.placeholder')"
          v-model="date"
        />
      </n-form-group>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import format from "../../mixins/format";

export default {
  name: "components.report.step-date",
  props: {
    claimType: String,
    contract: Object
  },
  mixin: [format],
  data: () => ({
    date: ""
  }),
  computed: {
    minDate() {
      if (this.claimType === "damage" && this.contract) {
        return moment(this.contract.starting_date, "YYYY-MM-DD").toDate();
      } else {
        return moment()
          .subtract(1, "years")
          .toDate();
      }
    },
    maxDate() {
      return moment().toDate();
    }
  },
  watch: {
    date(n) {
      if (n) {
        this.$parent.$parent.$emit("dataChange", { date: n });
        this.$parent.$parent.$emit(
          "pickedChange",
          this.format_date(n, "DD-MM-YYYY")
        );
        this.$parent.$parent.setCollapsed(true);
      } else {
        this.$parent.$parent.$emit("dataChange", {});
        this.$parent.$parent.$emit("pickedChange", null);
      }
    }
  }
};
</script>
