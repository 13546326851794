<template>
  <div>
    <div class="grid grid-rows-2 grid-flow-col gap-4 py-3 border-b">
      <div class="col-start-1 col-span-3 col-start-1 font-semibold">
        {{ title }}
      </div>
      <div class="col-start-1 col-span-3 row-start-2 col-start-2">
        <span v-if="value">{{ value }}</span>
        <div
          v-if="values && !extend_values"
          class="flex flex-row cursor-pointer"
          @click="extend_values = true"
        >
          <span class="text-brand-purple-500 underline mr-2">{{
            $t("report.common.expand.answers")
          }}</span>
          <n-icon name="Chevron-down" small />
        </div>
        <span
          class="text-brand-purple-500 underline cursor-pointer"
          v-if="values && extend_values"
          @click="extend_values = false"
          ><n-html :text="$t('report.common.close')"
        /></span>
      </div>
      <div v-if="values" class="col-start-1 col-span-4 row-start-3">
        <div v-if="extend_values">
          <div v-bind:key="item" v-for="(item, key) in values" class="mt-2">
            <hr />
            <p class="font-semibold mt-1">
              <n-html
                :text="$t('form.report_' + type + '_' + key + '.label')"
              />
            </p>
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div
        class="row-span-2 col-span-1 col-start-4 row-start-1 flex justify-end"
      >
        <span
          @click="$emit('edit', step)"
          class="text-brand-purple-500 underline  "
          ><n-html :text="$t('report.common.change')"
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirm-step-info",
  props: {
    step: String,
    title: String,
    value: String,
    values: [Array, Object],
    type: String
  },
  data: () => ({
    extend_values: false
  })
};
</script>

<style scoped></style>
