<template>
  <div class="w-full">
    <div class="pb-4" v-if="claimType === 'damage'">
      <p class="font-semibold">
        <n-html :text="$t('report.damage.area.select_area_title')" />
      </p>
      <p class="text-xs text-brand-gray-400">
        <n-html :text="$t('report.damage.area.select_area_subtitle')" />
      </p>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-8">
      <n-selector
        v-for="problemArea in problemAreas"
        v-bind:key="problemArea.code"
        :text="$t(problemArea.text)"
        :selected="hasSelectedArea(problemArea.code)"
        @click.native="selectArea(problemArea.code)"
      />
    </div>

    <div
      class="pt-8 grid grid-cols-1 lg:grid-cols-2 gap-6 text-xs sm:text-sm"
      v-if="areas.length"
    >
      <div>
        <div class="flex justify-between">
          <p class="font-semibold mb-2">
            <n-html
              :text="
                claimType === 'damage'
                  ? $t('form.report_damage_area.label')
                  : $t('form.report_theft_area.label')
              "
            />
          </p>
          <span
            :class="
              `text-xs text-brand-gray-400 ${
                description.length < 16 ? 'text-brand-red-500' : ''
              }`
            "
            >{{ description.length }}/250</span
          >
        </div>
        <n-form-group id="input-group-area-damage">
          <n-form-input-default
            id="input-area-damage"
            :placeholder="
              claimType === 'damage'
                ? $t('form.report_damage_area.placeholder')
                : $t('form.report_theft_area.placeholder')
            "
            v-model="description"
            field_type="textarea"
            rows="5"
            no-resize
            maxlength="250"
            minlength="16"
            required="true"
          />
        </n-form-group>
      </div>
    </div>
    <div v-if="areas.length && claimType === 'damage'">
      <div>
        <p class="font-semibold text-xs sm:text-sm mb-2">
          <n-html :text="$t('form.report_damage_pictures.label')" />
        </p>
        <div
          class="relative border border-dashed border-brand-purple-500 bg-brand-purple-100/25 cursor-pointer"
        >
          <p
            class="hidden lg:block absolute pl-6 top-1/2 -translate-y-1/2 left-0 right-1/3 text-left align-middle text-brand-purple-500"
          >
            <n-html :text="$t('form.report_damage_pictures.placeholder')" />
          </p>
          <n-button-regular
            @click.native="close"
            class="absolute left-0 lg:left-auto lg:right-0 top-1/2 -translate-y-1/2 mx-6 text-brand-purple-500 "
          >
            {{ $t("form.report.select_files_label") }}
          </n-button-regular>
          <input
            type="file"
            class="opacity-0 h-20 w-full cursor-pointer"
            multiple
            @change="onChange"
            accept="image/*"
          />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-6 py-2 mb-7">
          <div
            class="flex justify-between bg-brand-gray-100 text-xs text-brand-gray-400 px-3 py-1"
            v-for="file in files"
            :key="file.name"
          >
            {{ file.name }}
            <n-icon
              xs
              name="Delete"
              class="cursor-pointer"
              @click.native="removeFile(index)"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <n-button-regular
          @click.native="nextStep"
          :disabled="!dataValid"
          class="w-full sm:w-max"
        >
          <n-html :text="$t('common.next')" />
        </n-button-regular>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-end pt-6">
        <n-button-regular
          @click.native="nextStep"
          :disabled="!hasDescription"
          class="w-full sm:w-max"
        >
          <n-html :text="$t('form.report_theft_area.button')" />
        </n-button-regular>
      </div>
    </div>
  </div>
</template>

<script>
import ClaimConfiguration from "@/assets/json/claim_configuration.json";

export default {
  name: "components.report.step-area",
  props: {
    contract: Object,
    type: String,
    claimType: String
  },
  data: () => ({
    problemAreas: [],
    areas: [],
    description: "",
    screen_damage_type: null,
    checked: false,
    uploaded_files: [],
    files: []
  }),
  computed: {
    hasDescription() {
      return this.description !== "";
    },
    dataValid() {
      if (this.claimType === "damage") {
        return (
          this.areas.length &&
          this.files.length &&
          this.description.length >= 16
        );
      } else {
        return this.areas.length && this.description.length > 16;
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.uploaded_files = files;
      }
      this.uploaded_files.forEach(thisFile => {
        this.files.push({ name: thisFile.name, file: thisFile });
      });
      this.uploaded_files = [];
    },
    removeFile(i) {
      this.uploaded_files.splice(i, 1);
      this.files.splice(i, 1);
    },
    selectArea(area) {
      this.areas = [area];
    },
    hasSelectedArea(area) {
      return this.areas.includes(area);
    },
    persistData() {
      let files = [];
      this.files.forEach(file => {
        files.push(file.file);
      });
      let payload = {
        areas: this.areas,
        files: files,
        description: this.description
      };
      let picker = [];

      for (const area of this.areas) {
        let problemArea = this.problemAreas.find(p => p.code === area);
        picker.push(this.$t(problemArea.text));
      }

      let picked = picker.join(", ");

      this.$parent.$parent.$emit("dataChange", payload);
      this.$parent.$parent.$emit("pickedChange", picked);
    },
    resetData() {
      this.$parent.$parent.$emit("dataChange", {});
      this.$parent.$parent.$emit("pickedChange", "");
    },
    nextStep() {
      if (this.dataValid) {
        this.persistData();
      }
    }
  },
  watch: {
    dataValid(n) {
      if (!n) {
        this.resetData();
      }
    },
    areas() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    description() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    screen_damage_type() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    files() {
      // if (this.dataValid) {
      //   this.persistData();
      // }
    },
    nextStep() {
      if (this.dataValid) {
        this.persistData();
      }
    },
    type(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.problemAreas = [];
        this.areas = [];
        this.description = "";
        this.files = [];

        const category = ClaimConfiguration.objectCategories.find(
          objectCategory => objectCategory.name === this.contract.category_code
        );

        // Check if subgroups exist for category. If so, then check the subgroups for the right damage type (selected during step 3), to get the areas.
        if (category.subGroups !== undefined && category.subGroups.length > 0) {
          const subGroup = category.subGroups.find(
            group => group.code === this.contract.subgroup
          );
          if (subGroup) {
            let causes;
            if (this.claimType === "damage") {
              causes = subGroup.damageCause;
            } else if (this.claimType === "theft") {
              causes = subGroup.theftCause;
            }
            const cause = causes.find(cause => cause.code === newValue);

            if (cause) {
              this.problemAreas = cause.problemArea;
            }
          }
        }

        // If problemAreas is still empty, it means there are no subgroups or no subgroup for the damage type. Then select the standard areas.
        if (this.problemAreas.length <= 0) {
          let causes;
          if (this.claimType === "damage") {
            causes = category.damageCause;
          } else if (this.claimType === "theft") {
            causes = category.theftCause;
          }
          const cause = causes.find(cause => cause.code === newValue);
          if (cause) {
            this.problemAreas = cause.problemArea;
          }
        }

        this.resetData();
      }
    }
  }
};
</script>
