var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full grid grid-cols-2 gap-6 gap-x-0 sm:gap-x-6"},[_vm._l((_vm.contracts),function(contract){return _c('div',{key:contract.id,staticClass:"col-span-2 xl:col-span-1"},[(contract.multidevice)?_c('CardProductMultideviceSelector',{attrs:{"type":_vm.claimType,"contract":contract,"selected":_vm.device === contract},on:{"onProductSelect":function (selectedProduct) { return (_vm.product = selectedProduct); }},nativeOn:{"click":function($event){return _vm.selectDevice(contract)}}}):_c('CardProductSelector',{attrs:{"type":_vm.claimType,"contract":contract,"selected":_vm.device === contract},nativeOn:{"click":function($event){return _vm.selectDevice(contract)}}})],1)}),(
      _vm.claimType === 'damage' &&
        _vm.device &&
        _vm.device.manufacturer &&
        _vm.device.manufacturer.toLowerCase() === 'apple'
    )?_c('div',{staticClass:"col-span-2 flex justify-between"},[_c('checkbox',{attrs:{"label":_vm.$t('detail.claim_create.find_device_damage')},model:{value:(_vm.find_device),callback:function ($$v) {_vm.find_device=$$v},expression:"find_device"}})],1):_vm._e(),(
      _vm.claimType === 'theft' &&
        _vm.device &&
        _vm.device.manufacturer &&
        _vm.device.manufacturer.toLowerCase() === 'apple'
    )?_c('div',{staticClass:"col-span-2 flex justify-between"},[_c('checkbox',{attrs:{"label":_vm.$t('detail.claim_create.find_device_theft'),"value":!_vm.find_device},on:{"input":function (val) { return (this$1.find_device = !val); }}})],1):_vm._e(),_c('div',{staticClass:"col-span-2 flex items-center justify-between"},[_c('n-button-link',{nativeOn:{"click":function($event){return _vm.navigateTo('/insurances')}}},[_c('n-html',{staticClass:"text-xs sm:text-sm",attrs:{"text":_vm.$t(
            _vm.device && _vm.device.multidevice
              ? 'insurances.actions.add'
              : 'report.common.device.edit'
          )}})],1),_c('n-button-regular',{staticClass:"ml-auto py-2 px-4",attrs:{"disabled":!_vm.device || (_vm.device.multidevice && !_vm.product)},nativeOn:{"click":function($event){return _vm.accept()}}},[_c('n-html',{attrs:{"text":_vm.$t('report.common.device.confirm')}})],1)],1),_c('modal-device-popup',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}],attrs:{"template":_vm.template},on:{"close":_vm.closeModal}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }