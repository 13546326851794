<template>
  <n-card
    :class="[
      { 'card-active': !collapsed },
      'my-2 shadow rounded px-3 sm:px-6 border',
      collapsed
        ? 'border-brand-gray-300'
        : 'border-l-4 border-l-brand-purple-500'
    ]"
    no-margin
    no-padding
  >
    <div
      :class="[
        'flex justify-between font-semibold text-sm sm:text-lg items-center py-5',
        !collapsed ? 'text-brand-purple-500 ' : 'text-brand-gray-500'
      ]"
    >
      <div class="flex items-center">
        <div
          :class="[
            'flex  text-base justify-center items-center rounded-full border',
            !collapsed || hasData
              ? 'text-brand-purple-500 bg-brand-purple-100/25 border-brand-purple-500'
              : 'text-brand-gray-400 font-medium bg-brand-gray-400/10 border-brand-gray-400',
            collapsed ? ' w-8 h-8 min-w-8' : 'w-10 min-w-10 h-10 border-2'
          ]"
        >
          {{ step }}
        </div>
        <div class="pl-6">
          <p class="text-sm sm:text-lg">{{ title }}</p>
          <p
            v-if="hasData && collapsed"
            class="text-brand-gray-400 text-xs sm:text-sm font-normal break-words	"
          >
            <span class="font-semibold pr-4 " v-if="picked">
              <n-html :text="$t('report.common.picked')" />:
            </span>
            {{ picked }}
          </p>
        </div>
      </div>
      <div class="text-brand-purple-500 text-sm font-normal text-xs sm:text-sm">
        <a
          v-if="hasData && collapsed"
          class="underline cursor-pointer"
          @click="setCollapsed(false)"
        >
          <n-html :text="$t('report.common.change')" />
        </a>
        <a
          v-else-if="hasData && manual_close"
          class="underline cursor-pointer"
          @click="setCollapsed(true)"
        >
          <n-html :text="$t('report.common.close')" />
        </a>
      </div>
    </div>
    <div :class="`text-sm py-6 flex ${collapsed ? 'hidden' : 'border-t'}`">
      <slot />
    </div>
  </n-card>
</template>

<script>
import _ from "lodash";

export default {
  name: "components.report.card-step",
  data: () => ({
    collapsed: true,
    data: {},
    picked: null
  }),
  props: {
    step: String,
    title: String,
    manual_close: Boolean
  },
  computed: {
    hasData() {
      return !_.isEmpty(this.data);
    }
  },
  methods: {
    setCollapsed(collapsed) {
      this.collapsed = collapsed;
    }
  },
  watch: {
    data(n) {
      this.$emit("dataChange", n);
    }
  },
  mounted() {
    this.$on("dataChange", d => (this.data = d));
    this.$on("pickedChange", d => (this.picked = d));
  }
};
</script>
