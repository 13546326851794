<template>
  <div class="grid grid-cols-3 gap-6 gap-y-12 sm:gap-y-6 sm:gap-6 w-full">
    <div class="col-span-3 lg:col-span-2 flex items-end">
      <p>
        <n-html :text="$t('report.common.personal_information.subtitle')" />
      </p>
    </div>

    <div class="col-span-3 lg:col-span-2">
      <h1 class="border-b pb-3 font-serif font-semibold text-base sm:text-xl">
        <n-html :text="$t('details.personal.forms.personal')" />
      </h1>

      <div
        class="border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
      >
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.title.label')" />
          </div>
          <div>{{ title }}</div>
        </div>
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.initials.label')" />
          </div>
          <div>{{ customer.initials }}</div>
        </div>
      </div>
      <div
        class="border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
      >
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.first_name.label')" />
          </div>
          <div>{{ customer.first_name || "-" }}</div>
        </div>
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.middle_name.label')" />
          </div>
          <div>{{ customer.middle_name || "-" }}</div>
        </div>
      </div>
      <div
        class="border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
      >
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.last_name.label')" />
          </div>
          <div>{{ customer.surname }}</div>
        </div>
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.birth_date.label')" />
          </div>
          <div>{{ this.format_date(customer.birth_date, "DD-MM-YYYY") }}</div>
        </div>
      </div>
      <div
        class="sm:border-b pb-1 pt-1 leading-7 font-serif grid sm:grid-cols-2 text-xs sm:text-sm"
      >
        <div class="border-b pb-1 sm:pb-0 sm:border-none">
          <div class="font-semibold">
            <n-html :text="$t('form.email.label')" />
          </div>
          <div>{{ customer.email }}</div>
        </div>
        <div>
          <div>
            <div class="font-semibold">
              <n-html :text="$t('form.phone.label')" />
            </div>
            <div class="flex sm:flex sm:flex-row w-full justify-between">
              <div>
                {{ customer.phone }}
              </div>

              <div>
                <n-button-link @click.native="navigateTo('/details/personal')">
                  <n-html :text="$t('details.common.forms.edit')" />
                </n-button-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-3 lg:col-span-2">
      <h2 class="border-b pb-3 font-serif font-semibold text-base sm:text-xl">
        <n-html :text="$t('details.address.title')" />
      </h2>

      <div
          class="border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
      >
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.street_name.label')" />
          </div>
          <div>{{ customer.street_name || "-" }}</div>
        </div>
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.street_number.label')" />
          </div>
          <div>{{ customer.street_number || "-" }} {{ customer.street_number_addition || ""}}</div>
        </div>
      </div>
      <div
          class="border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
      >
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.zip_code.label')" />
          </div>
          <div>{{ customer.zip_code || "-" }}</div>
        </div>
        <div>
          <div>
            <div class="font-semibold">
              <n-html :text="$t('form.city.label')" />
            </div>
            <div class="flex sm:flex sm:flex-row w-full justify-between">
              <div>
                {{ customer.city }}
              </div>

              <div>
                <n-button-link @click.native="navigateTo('/details/personal')">
                  <n-html :text="$t('details.common.forms.edit')" />
                </n-button-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-3 lg:col-span-2">
      <h2 class="border-b pb-3 font-serif font-semibold text-base sm:text-xl">
        <n-html :text="$t('details.payment.title')" />
      </h2>

      <div
        class="sm:border-b pb-1 pt-1 leading-7 font-serif grid grid-cols-2 text-xs sm:text-sm"
        v-for="iban in mergedIbans"
        :key="iban.contractNo"
      >
        <div class="col-span-2">
          <div class="font-semibold text-xs sm:text-sm">
            <n-html :text="$t('form.iban.label')" />
          </div>
          <div class="flex flex-wrap justify-between text-xs sm:text-sm">
            <span>
              {{ iban.iban }}
            </span>

            <n-button-link @click.native="navigateTo('/details/payment')">
              <n-html
                :text="$t('details.common.forms.edit')"
                class="text-xs sm:text-sm"
              />
            </n-button-link>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-3 lg:col-span-3 flex items-end mt-6">
      <n-button-regular @click.native="accept()" class="ml-auto">
        <n-html :text="$t('report.common.personal_information.confirm')" />
      </n-button-regular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "components.report.step-personal-information",
  mixin: [],
  data: () => ({
    accepted: "",
    mergedIbans: []
  }),
  created() {
    this.$store.dispatch("customer/get_ibans").finally(() => {
      this.mergedIbans = this.getMergedIbanList();
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters({
      title: "customer/getTitle",
      customer: "customer/get",
      mutation: "customer/getMutation",
      user: "user/get",
      emailChangeRequest: "user/getEmailChange",
      ibans: "customer/get_ibans"
    }),
    computed: {
      yes() {
        return this.$t("report.common.required_question.yes");
      },
      no() {
        return this.$t("report.common.required_question.no");
      }
    }
  },
  methods: {
    navigateTo: function(path) {
      this.$router.push(path);
    },
    accept: function() {
      this.$parent.$parent.$emit("dataChange", { accepted: true });
      this.$parent.$parent.$emit("pickedChange", this.yes);
      this.$parent.$parent.setCollapsed(true);
    },
    getMergedIbanList() {
      var mergedArray = [];
      this.ibans.forEach(element => {
        const filteredMergeArray = mergedArray.filter(mergedElement => {
          if (mergedElement.iban == element.iban) return mergedElement;
        });
        if (filteredMergeArray.length == 0) {
          mergedArray.push(element);
        } else {
          const index = mergedArray.findIndex(
            e =>
              e.iban === element.iban &&
              e.paymentMethodCode === element.paymentMethodCode
          );
          if (index >= 0) {
            let elementToMerge = mergedArray[index];
            elementToMerge.contractNo =
              elementToMerge.contractNo + `, ${element.contractNo}`;
          } else {
            mergedArray.push(element);
          }
        }
      });
      return mergedArray;
    }
  }
};
</script>
