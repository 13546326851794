<template>
  <n-card
    :class="[
      'relative cursor-pointer rounded px-3 sm:px-5 py-4',
      selected ? 'border border-brand-purple-500 bg-brand-purple-100/25' : '',
      isCardDisabled() ? 'bg-brand-gray-300' : ''
    ]"
    no-margin
    no-padding
  >
    <n-icon
      v-if="selected"
      class="absolute top-2 right-2"
      name="Check"
      small
    ></n-icon>
    <div
      class="grid grid-cols-12 text-xs sm:text-sm sm:flex content-center items-center"
    >
      <n-icon
        class="col-span-2 mb-2 sm:mb-0"
        :name="contract.category"
        auto
      ></n-icon>
      <div class="col-span-10 pl-4 grid place-content-center">
        <p class="text-xs sm:text-sm font-semibold">
          {{ contract.description }}
        </p>
        <p class="text-sm mt-1 sm:mt-0 font-semibold" v-if="contract.imei">
          <n-html :text="$t('report.common.device.imei')" />:
          {{ contract.imei }}
        </p>
        <p
          class="text-xs mt-1 sm:mt-0 sm:text-sm font-semibold"
          v-if="contract.serial_number"
        >
          <n-html :text="$t('report.common.device.serial_number')" />:
          {{ contract.serial_number }}
        </p>
        <p class="text-xs font-normal mt-1 sm:mt-0">
          {{ contract.contract_description }}
        </p>
        <p v-if="isCardDisabled()" class="text-red-500 mt-2 sm:mt-0">
          {{ type === "damage" ? disabledDamageText() : disabledTheftText() }}
        </p>
      </div>
    </div>
  </n-card>
</template>

<script>
export default {
  name: "components.products.card-product-selector",
  props: {
    type: String,
    contract: Object,
    selected: Boolean
  },
  created() {
    console.log(this.contract);
  },
  methods: {
    isCardDisabled() {
      if (this.type === "damage") {
        return (
          this.contract.claim_exists || this.contract.late_payment_amount > 0
        );
      } else if (this.type === "theft") {
        if (this.contract.theft_coverage) {
          return (
            this.contract.claim_exists || this.contract.late_payment_amount > 0
          );
        }
      }
      return true;
    },
    disabledDamageText() {
      if (this.contract.late_payment_amount > 0) {
        return this.$t("report.common.product_disabled.late_payment");
      } else {
        return this.$t("report.common.product_disabled.damage");
      }
    },
    disabledTheftText() {
      if (this.contract.late_payment_amount > 0) {
        return this.$t("report.common.product_disabled.late_payment");
      } else if (this.contract.theft_coverage && this.contract.claim_exists) {
        return this.$t("report.common.product_disabled.theft_claim_exists");
      } else {
        return this.$t("report.common.product_disabled.theft_no_coverage");
      }
    }
  }
};
</script>
