<template>
  <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-6">
    <n-selector
      v-for="cause in filteredCauses"
      v-bind:key="cause.code"
      :icon="getCauseIcon(cause.code)"
      :text="$t(cause.text)"
      :selected="type === cause.code"
      @click.native="selectType(cause.code, cause.text)"
    />
  </div>
</template>

<script>
import ClaimConfiguration from "@/assets/json/claim_configuration.json";

export default {
  name: "components.report.step-type",
  props: {
    claimType: String,
    contract: Object
  },
  data: () => ({
    type: null,
    picked: "",
    category: "",
    causes: [],
    filteredCauses: []
  }),
  methods: {
    selectType(type, picked) {
      this.type = type;
      this.picked = picked;
      this.$parent.$parent.setCollapsed(true);
    },
    getCauseIcon(code) {
      switch (code) {
        case "TECHNISCH DEFECT":
          return "Technisch";
        case "VOCHTSCHADE":
          return "Water";
        case "VALSCHADE":
          return "val-stoot-druk";
        default:
          return "";
      }
    }
  },
  watch: {
    type(n) {
      this.$parent.$parent.$emit("dataChange", { type: n });
    },
    picked(n) {
      this.$parent.$parent.$emit("pickedChange", this.$t(n));
    },
    contract(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.type = null;
        this.picked = "";
        this.causes = [];
        this.category = newValue.category_code;

        const _subgroup = newValue.subgroup;
        const _category = ClaimConfiguration.objectCategories.find(
          objectCategory => objectCategory.name === this.category
        );

        // Check if subgroups exist for category. If so, then show the damage types for that subgroup
        if (
          _category.subGroups !== undefined &&
          _category.subGroups.length > 0
        ) {
          const subGroup = _category.subGroups.find(
            group => group.code === _subgroup
          );
          if (subGroup) {
            if (this.claimType === "damage") {
              this.causes = subGroup.damageCause;
            } else if (this.claimType === "theft") {
              this.causes = subGroup.theftCause;
            }
          }
        }

        // If causes is still empty, it means there are no subgroups. Then select the standard damage causes.
        if (this.causes.length <= 0) {
          if (this.claimType === "damage") {
            this.causes = _category.damageCause;
          } else if (this.claimType === "theft") {
            this.causes = _category.theftCause;
          }
        }
      }
    },
    causes(n) {
      const filteredArray = [];
      for (const cause of n) {
        switch (cause.code) {
          case "TECHNISCH DEFECT":
            if (
              this.contract.technical_defect &&
              this.contract.technical_defect.toLowerCase() === "yes"
            ) {
              filteredArray.push(cause);
            }
            break;
          case "VOCHTSCHADE":
            if (
              this.contract.water_damage &&
              this.contract.water_damage.toLowerCase() === "yes"
            ) {
              filteredArray.push(cause);
            }
            break;
          case "VALSCHADE":
            if (
              this.contract.fall_and_impact_damage &&
              this.contract.fall_and_impact_damage.toLowerCase() === "yes"
            ) {
              filteredArray.push(cause);
            }
            break;
          case "INBRAAK":
            if (this.contract.theft_coverage) {
              filteredArray.push(cause);
            }
            break;
          case "BEROVING":
            if (this.contract.theft_coverage) {
              filteredArray.push(cause);
            }
            break;
          case "VERMISSING":
            if (this.contract.theft_coverage) {
              filteredArray.push(cause);
            }
            break;
          default:
            break;
        }
      }
      this.filteredCauses = filteredArray;
    }
  }
};
</script>
