<template>
  <n-card
    :class="[
      'relative cursor-pointer rounded px-3 sm:px-5 py-4',
      selected ? 'border border-brand-purple-500 bg-brand-purple-100/25' : '',
      isCardDisabled() ? 'bg-brand-gray-300' : ''
    ]"
    no-margin
    no-padding
  >
    <n-icon v-if="selected" class="absolute top-2 right-2" name="Check" small />
    <div
      class="grid grid-cols-12 text-xs sm:text-sm sm:flex content-center items-center"
      :class="selected ? 'border-b-2 pb-5' : ''"
    >
      <n-icon
        class="col-span-2 mb-2 sm:mb-0"
        :name="contract.category"
        auto
      ></n-icon>
      <div class="col-span-10 pl-4 grid place-content-center">
        <p class="text-xs sm:text-sm font-semibold">
          {{ contract.contract_description }}
        </p>
        <p class="text-xs font-normal mt-1 sm:mt-0">
          Polisnummer: {{ contract.navision_ext_id }}
        </p>
        <p v-if="isCardDisabled()" class="text-red-500 mt-2 sm:mt-0">
          {{ type === "damage" ? disabledDamageText() : disabledTheftText() }}
        </p>
      </div>
    </div>
    <div v-if="selected" class="text-xs mt-5">
      <span class="font-semibold">Selecteer het juiste product:</span>
      <n-form-group
        class="flex flex-col gap-y-3"
        id="input-group-select_device"
      >
        <div
          class="flex align-middle gap-x-3"
          :key="product.id"
          v-for="product in contract.product_set"
        >
          <input
            :disabled="product.claim_exists"
            type="radio"
            :id="`input-${product.id}`"
            :value="product.id"
            v-model="selectedDevice"
          />
          <label :for="`input-${product.id}`">
            <span>{{ product.description }}</span>
            <small v-if="product.claim_exists">
              <br />
              Er staat al een claim open voor dit product
            </small>
          </label>
        </div>
      </n-form-group>
    </div>
  </n-card>
</template>

<script>
export default {
  name: "components.products.card-product-multidevice-selector",
  props: {
    type: String,
    contract: Object,
    selected: Boolean
  },
  data() {
    return {
      selectedDevice: null
    };
  },
  methods: {
    isCardDisabled() {
      return (
        (this.type === "theft" && !this.contract.theft_coverage) ||
        this.contract.late_payment_amount > 0
      );
    },
    disabledDamageText() {
      if (this.contract.late_payment_amount > 0) {
        return this.$t("report.common.product_disabled.late_payment");
      } else {
        return this.$t("report.common.product_disabled.damage");
      }
    },
    disabledTheftText() {
      if (this.contract.late_payment_amount > 0) {
        return this.$t("report.common.product_disabled.late_payment");
      } else if (this.contract.theft_coverage && this.contract.claim_exists) {
        return this.$t("report.common.product_disabled.theft_claim_exists");
      } else {
        return this.$t("report.common.product_disabled.theft_no_coverage");
      }
    }
  },
  watch: {
    selectedDevice() {
      this.$emit("onProductSelect", this.selectedDevice);
    }
  }
};
</script>
