<template>
  <div
    class="flex flex-col fixed inset-0 bg-gray-600 bg-opacity-50 w-full h-full items-center content-center justify-center z-50"
  >
    <div
      class="flex flex-col my-10 mx-10 sm:mx-auto w-full sm:w-2/3 xl:w-1/2 shadow-lg rounded-md bg-white overflow-hidden"
    >
      <header
        :class="[
          'flex justify-between',
          small ? 'px-8 py-6' : 'border-b px-12 py-8'
        ]"
      >
        <h2
          class="uppercase text-brand-purple-500 font-serif font-bold text-2xl"
        >
          <n-html :text="$t(template.translation_key)" />
        </h2>
        <button type="button" @click="$emit('close')">
          <span class="sr-only">Close sidebar</span>
          <svg
            class="h-6 w-6 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#343A40"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </header>
      <main
        :class="[
          'overflow-y-scroll h-full',
          small ? 'px-8 pb-4 pt-0' : 'px-12 py-6'
        ]"
      >
        <img class="max-w-full" :src="template.customer_image" alt="" />
        <n-html class="py-4" :text="template.customer_information" />
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-device-popup",
  props: {
    template: Object
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    small() {
      return "small" in this.$attrs;
    },
    noFooter() {
      return "no-footer" in this.$attrs;
    }
  }
};
</script>
