<template>
  <div
    class="flex flex-col fixed inset-0 bg-gray-600 bg-opacity-50 w-full h-full items-center content-center justify-center z-50 px-5"
  >
    <div
      class="flex flex-col my-10 mx-10 sm:mx-auto w-full sm:w-2/3 xl:w-1/2 shadow-lg rounded-md bg-white sm:overflow-hidden overflow-y-auto"
    >
      <header
        :class="[
          'flex justify-between',
          small
            ? 'py-4 px-6 sm:px-8 sm:py-6'
            : 'border-b px-6 sm:px-12 py-4 sm:py-8'
        ]"
      >
        <h2
          class="uppercase text-brand-purple-500 font-serif font-bold text-xl md:text-2xl"
        >
          <n-html :text="title" />
        </h2>
        <button class="sm:pl-0 pl-2" type="button" @click="$emit('close')">
          <span class="sr-only">Close sidebar</span>
          <svg
            class="h-6 w-6 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#343A40"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </header>
      <main
        :class="[
          'overflow-y-scroll h-full',
          small ? 'px-5 sm:px-8 pb-4 pt-0' : 'px-6 sm:px-12 sm:py-6'
        ]"
      >
        <slot name="default"></slot>
      </main>
      <footer
        v-if="!noFooter"
        class="flex flex-row items-center justify-between px-3 sm:px-12 py-5 bg-brand-gray-100 rounded-b"
      >
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "default-modal",
  props: {
    title: String
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    small() {
      if ("small" in this.$attrs) {
        if (this.$attrs["small"] !== "") {
          return this.$attrs["small"];
        }
        return true;
      }
      return false;
    },
    noFooter() {
      if ("no-footer" in this.$attrs) {
        if (this.$attrs["no-footer"] !== "") {
          return this.$attrs["no-footer"];
        }
        return true;
      }
      return false;
    }
  }
};
</script>
