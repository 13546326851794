<template>
  <div class="w-full grid grid-cols-2 gap-6 gap-x-0 sm:gap-x-6">
    <div
      v-for="contract in contracts"
      v-bind:key="contract.id"
      class="col-span-2 xl:col-span-1"
    >
      <CardProductMultideviceSelector
        @click.native="selectDevice(contract)"
        @onProductSelect="selectedProduct => (product = selectedProduct)"
        :type="claimType"
        :contract="contract"
        :selected="device === contract"
        v-if="contract.multidevice"
      />
      <CardProductSelector
        @click.native="selectDevice(contract)"
        :type="claimType"
        :contract="contract"
        :selected="device === contract"
        v-else
      />
    </div>
    <div
      class="col-span-2 flex justify-between"
      v-if="
        claimType === 'damage' &&
          device &&
          device.manufacturer &&
          device.manufacturer.toLowerCase() === 'apple'
      "
    >
      <checkbox
        :label="$t('detail.claim_create.find_device_damage')"
        v-model="find_device"
      ></checkbox>
    </div>
    <div
      class="col-span-2 flex justify-between"
      v-if="
        claimType === 'theft' &&
          device &&
          device.manufacturer &&
          device.manufacturer.toLowerCase() === 'apple'
      "
    >
      <checkbox
        @input="val => (this.find_device = !val)"
        :label="$t('detail.claim_create.find_device_theft')"
        :value="!find_device"
      ></checkbox>
    </div>
    <div class="col-span-2 flex items-center justify-between">
      <n-button-link @click.native="navigateTo('/insurances')">
        <n-html
          :text="
            $t(
              device && device.multidevice
                ? 'insurances.actions.add'
                : 'report.common.device.edit'
            )
          "
          class="text-xs sm:text-sm"
        />
      </n-button-link>

      <n-button-regular
        @click.native="accept()"
        class="ml-auto py-2 px-4"
        :disabled="!device || (device.multidevice && !product)"
      >
        <n-html :text="$t('report.common.device.confirm')" />
      </n-button-regular>
    </div>
    <modal-device-popup
      :template="template"
      v-show="isModalVisible"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardProductSelector from "../products/card-product-selector";
import ModalDevicePopup from "./step-device-popup";
import format from "@/mixins/format";
import checkbox from "../../components/theme/form/input/checkbox";
import CardProductMultideviceSelector from "@/components/products/card-product-multidevice-selector";

export default {
  name: "components.report.step-device",
  components: {
    CardProductSelector,
    CardProductMultideviceSelector,
    ModalDevicePopup,
    checkbox
  },
  mixin: [format],
  props: {
    contract: Object,
    claimType: String,
    collapsed: Boolean,
    active: Boolean
  },
  data: () => ({
    device: null,
    selectedDevice: null,
    internal_collapsed: true,
    isModalVisible: false,
    templates: null,
    acceptance: false,
    template: {},
    product: null,
    find_device: false
  }),
  computed: {
    ...mapGetters({
      contracts: "contracts/getAllActive"
    }),
    getContracts() {
      if (this.claimType == "theft") {
        return this.contracts.filter(x => x.theft_coverage);
      }
      return this.contracts;
    }
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    accept() {
      if (this.claimType === "damage") {
        for (let i = 0; i < this.templates.length; i++) {
          if (this.templates[i].template_no === this.device.template_no) {
            if (
              !this.templates[i].category_code ||
              this.templates[i].category_code == this.device.category_code
            ) {
              this.acceptance = true;
              this.template = this.templates[i];
            }
          }
        }
        if (this.device) {
          if (this.acceptance === true) {
            this.isModalVisible = true;
            this.acceptance = false;
          } else {
            this.selectedDevice = this.device;
          }
        }
      } else {
        this.selectedDevice = this.device;
      }
    },
    showEditModal(value) {
      this.edit_modal_visible = value;
    },
    getAllTemplates() {
      return this.$store
        .dispatch("claims/getAllTemplates")
        .then(
          () => (this.templates = this.$store.getters["claims/getAllTemplates"])
        )
        .catch(() => (this.storeNotFound = true));
    },
    selectDevice(device) {
      if (this.claimType === "damage") {
        if (device.multidevice) {
          this.device = device;
        } else if (!device.claim_exists && device.late_payment_amount === 0) {
          this.device = device;
        } else {
          this.device = null;
        }
      } else if (this.claimType === "theft") {
        if (device.theft_coverage) {
          if (!device.claim_exists && device.late_payment_amount === 0) {
            this.device = device;
          } else {
            this.device = null;
          }
        } else {
          this.device = null;
        }
      } else {
        this.device = device;
      }
    }
  },
  mounted() {
    this.templates = this.getAllTemplates().data;
    this.internal_collapsed = this.collapsed;
    this.find_device = this.claimType === "damage" ? true : false;
  },
  watch: {
    selectedDevice(n) {
      this.$parent.$parent.$emit("dataChange", {
        contract: n,
        product: this.product,
        find_device: this.find_device
      });
      this.$parent.$parent.$emit("pickedChange", n.description);

      if (this.device != null) {
        this.$parent.$parent.setCollapsed(true);
      }
    }
  },
  created() {
    // if (this.contracts && this.contracts.length === 1) {
    //   this.selectDevice(this.contracts[0]);
    // }
  }
};
</script>
