<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
    <p>
      {{ subtitle }}
    </p>
    <div>
      <n-form-group
        id="input-required-question"
        :invalid-feedback="error"
        :state="state"
      >
        <div v-for="option in options" :key="option.value">
          <label :for="option.text" class="cursor-pointer">
            <input
              :id="option.text"
              v-model="selected"
              type="radio"
              :value="option.value"
              class="mr-2"
            />
            {{ option.text }}
          </label>
        </div>
      </n-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "components.report.step-required-question",
  data: () => ({
    options: [
      { text: "", value: true },
      { text: "", value: false }
    ],
    selected: null,
    state: null
  }),
  props: {
    subtitle: String,
    error: String,
    no_collapse_on_false: Boolean
  },
  computed: {
    yes() {
      return this.$t("report.common.required_question.yes");
    },
    no() {
      return this.$t("report.common.required_question.no");
    }
  },
  watch: {
    selected(n) {
      this.state = null;
      if (n) {
        this.$parent.$parent.$emit("dataChange", { selected: n });
        this.$parent.$parent.$emit("pickedChange", this.yes);
        this.$parent.$parent.setCollapsed(true);
        this.state = true;
      } else {
        this.$parent.$parent.$emit("dataChange", { selected: n });
        this.$parent.$parent.$emit("pickedChange", this.no);
        if (this.no_collapse_on_false != true) {
          this.$parent.$parent.setCollapsed(true);
        }
        this.state = false;
      }
    }
  },
  created() {
    this.options[0].text = this.yes;
    this.options[1].text = this.no;
  }
};
</script>
