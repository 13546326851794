var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col fixed inset-0 bg-gray-600 bg-opacity-50 w-full h-full items-center content-center justify-center z-50 px-5"},[_c('div',{staticClass:"flex flex-col my-10 mx-10 sm:mx-auto w-full sm:w-2/3 xl:w-1/2 shadow-lg rounded-md bg-white sm:overflow-hidden overflow-y-auto"},[_c('header',{class:[
        'flex justify-between',
        _vm.small
          ? 'py-4 px-6 sm:px-8 sm:py-6'
          : 'border-b px-6 sm:px-12 py-4 sm:py-8'
      ]},[_c('h2',{staticClass:"uppercase text-brand-purple-500 font-serif font-bold text-xl md:text-2xl"},[_c('n-html',{attrs:{"text":_vm.title}})],1),_c('button',{staticClass:"sm:pl-0 pl-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close sidebar")]),_c('svg',{staticClass:"h-6 w-6 text-black",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"#343A40","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","d":"M6 18L18 6M6 6l12 12"}})])])]),_c('main',{class:[
        'overflow-y-scroll h-full',
        _vm.small ? 'px-5 sm:px-8 pb-4 pt-0' : 'px-6 sm:px-12 sm:py-6'
      ]},[_vm._t("default")],2),(!_vm.noFooter)?_c('footer',{staticClass:"flex flex-row items-center justify-between px-3 sm:px-12 py-5 bg-brand-gray-100 rounded-b"},[_vm._t("footer")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }