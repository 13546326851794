var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('n-card',{class:[
    { 'card-active': !_vm.collapsed },
    'my-2 shadow rounded px-3 sm:px-6 border',
    _vm.collapsed
      ? 'border-brand-gray-300'
      : 'border-l-4 border-l-brand-purple-500'
  ],attrs:{"no-margin":"","no-padding":""}},[_c('div',{class:[
      'flex justify-between font-semibold text-sm sm:text-lg items-center py-5',
      !_vm.collapsed ? 'text-brand-purple-500 ' : 'text-brand-gray-500'
    ]},[_c('div',{staticClass:"flex items-center"},[_c('div',{class:[
          'flex  text-base justify-center items-center rounded-full border',
          !_vm.collapsed || _vm.hasData
            ? 'text-brand-purple-500 bg-brand-purple-100/25 border-brand-purple-500'
            : 'text-brand-gray-400 font-medium bg-brand-gray-400/10 border-brand-gray-400',
          _vm.collapsed ? ' w-8 h-8 min-w-8' : 'w-10 min-w-10 h-10 border-2'
        ]},[_vm._v(" "+_vm._s(_vm.step)+" ")]),_c('div',{staticClass:"pl-6"},[_c('p',{staticClass:"text-sm sm:text-lg"},[_vm._v(_vm._s(_vm.title))]),(_vm.hasData && _vm.collapsed)?_c('p',{staticClass:"text-brand-gray-400 text-xs sm:text-sm font-normal break-words\t"},[(_vm.picked)?_c('span',{staticClass:"font-semibold pr-4 "},[_c('n-html',{attrs:{"text":_vm.$t('report.common.picked')}}),_vm._v(": ")],1):_vm._e(),_vm._v(" "+_vm._s(_vm.picked)+" ")]):_vm._e()])]),_c('div',{staticClass:"text-brand-purple-500 text-sm font-normal text-xs sm:text-sm"},[(_vm.hasData && _vm.collapsed)?_c('a',{staticClass:"underline cursor-pointer",on:{"click":function($event){return _vm.setCollapsed(false)}}},[_c('n-html',{attrs:{"text":_vm.$t('report.common.change')}})],1):(_vm.hasData && _vm.manual_close)?_c('a',{staticClass:"underline cursor-pointer",on:{"click":function($event){return _vm.setCollapsed(true)}}},[_c('n-html',{attrs:{"text":_vm.$t('report.common.close')}})],1):_vm._e()])]),_c('div',{class:("text-sm py-6 flex " + (_vm.collapsed ? 'hidden' : 'border-t'))},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }